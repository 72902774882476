<template>
  <div class="kepzesek container">
    <h1>&nbsp;</h1>
    <h1 v-if="!this.jo" class="icon">404! Az oldal nem található!</h1>
    <div v-if="this.jo" class="row">
      <div class="col-12">
        <h3>1. Tábor adatai</h3>
        <div id="divUnder1200px" class="row">
          <div class="form-floating mb-3">
            <textarea
              class="form-control rounded-3"
              placeholder="Leave a comment here"
              id="floatingTaborMegnevezese"
              style="
                height: 130px;
                font-size: 14px;
                font-weight: bold;
                min-height: 130px;
              "
              v-model="this.tabornev"
              disabled
            ></textarea>
            <label for="floatingTaborMegnevezese">Tábor adatai</label>
          </div>
        </div>
        <div id="divAbove1200px" class="row">
          <div class="form-floating mb-3">
            <textarea
              class="form-control rounded-3"
              placeholder="Leave a comment here"
              id="floatingTaborMegnevezese"
              style="
                height: 163px;
                font-size: 20px;
                font-weight: bold;
                min-height: 163px;
              "
              v-model="this.tabornev"
              disabled
            ></textarea>
            <label for="floatingTaborMegnevezese">Tábor adatai</label>
          </div>
        </div>
        <div v-if="adatlapLathato">
          <form v-on:submit.prevent="jelentkezoTarol" class="">
            <h3>2. Gyermek adatai</h3>
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="jelentkezoNev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="JelentkezoNev"
                    required
                    v-model="jelentkezo.jelentkezoNev"
                  />
                  <label for="jelentkezoNev">Név *</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="jelentkezoSzhely"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="JelentkezoSzhely"
                    required
                    v-model="jelentkezo.jelentkezoSzhely"
                  />
                  <label for="jelentkezoSzhely">Születési hely *</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="jelentkezoSzido"
                    type="date"
                    class="form-control rounded-3"
                    placeholder="JelentkezoSzido"
                    v-model="jelentkezo.jelentkezoSzido"
                    required
                  />
                  <label for="jelentkezoSzido">Születési idő *</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="jelentkezoTaj"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="JelentkezoTAJ"
                    maxlength="9"
                    pattern="\d{9}"
                    required
                    v-model="jelentkezo.jelentkezoTaj"
                    v-on:blur="tajEllenorzes()"
                  />
                  <label for="jelentkezoTaj">TAJ szám *</label>
                </div>
              </div>
              <div class="col-md-2 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="jelentkezoCimIrszam"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="JelentkezoCimIrszam"
                    maxlength="4"
                    pattern="\d{4}"
                    @blur="szamlazasCimIrszamBeir()"
                    required
                    v-model="jelentkezo.jelentkezoCimIrszam"
                  />
                  <label for="jelentkezoCimIrszam">Irányítószám *</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="jelentkezoCimVaros"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="JelentkezoCimVaros"
                    @blur="szamlazasCimVarosBeir()"
                    required
                    v-model="jelentkezo.jelentkezoCimVaros"
                  />
                  <label for="jelentkezoCimVaros">Település *</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="jelentkezoCimCim"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="JelentkezoCimCim"
                    @blur="szamlazasCimCimBeir()"
                    required
                    v-model="jelentkezo.jelentkezoCimCim"
                  />
                  <label for="jelentkezoCimCim"
                    >Utca, házszám, épület, emelet, ajtó *</label
                  >
                </div>
              </div>
            </div>
            <h3>3. Szülő/gondviselő adatai</h3>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szuloNev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzuloNev"
                    required
                    v-model="jelentkezo.szuloNev"
                  />
                  <label for="szuloNev">Név *</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szuloTelefon"
                    type="tel"
                    class="form-control rounded-3"
                    placeholder="+36301234567"
                    pattern="((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})"
                    required
                    v-model="jelentkezo.szuloTelefon"
                  />
                  <label for="szuloTelefon"
                    >Telefonszám * (+36-xx-xxx-xxxx v. +36-xx-xxx-xxx)</label
                  >
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szuloEmail"
                    type="email"
                    class="form-control rounded-3"
                    placeholder="SzuloEmail"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                    required
                    v-model="jelentkezo.szuloEmail"
                  />
                  <label for="szuloEmail">E-mail cím *</label>
                </div>
              </div>
              <!--div class="col-md-2 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szuloKapcsolat"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzuloKapcsolat"
                    required
                    v-model="jelentkezo.szuloKapcsolat"
                  />
                  <label for="szuloKapcsolat">Kapcsolat *</label>
                </div>
              </div-->
            </div>
            <h3>4. Számlázási adatok</h3>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szamlazasNev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzamlazasNev"
                    required
                    v-model="jelentkezo.szamlazasNev"
                  />
                  <label for="szamlazasNev">Számlázási név *</label>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szamlazasAdoazon"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzamlazasAdoazon"
                    v-model="jelentkezo.szamlazasAdoazon"
                    required
                  />
                  <label for="szamlazasAdoazon">Adóazonosító *</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szamlazasCimIrszam"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzamlazasCimIrszam"
                    maxlength="4"
                    pattern="\d{4}"
                    required
                    v-model="jelentkezo.szamlazasCimIrszam"
                  />
                  <label for="szamlazasCimIrszam">Irányítószám *</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szamlazasCimVaros"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzamlazasCimVaros"
                    required
                    v-model="jelentkezo.szamlazasCimVaros"
                  />
                  <label for="szamlazasCimVaros">Település *</label>
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szamlazasCimCim"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzamlazasCimCim"
                    required
                    v-model="jelentkezo.szamlazasCimCim"
                  />
                  <label for="szamlazasCimCim"
                    >Utca, házszám, épület, emelet, ajtó *</label
                  >
                </div>
              </div>
            </div>
            <h3>5. Étkezési információk</h3>
            <div class="row">
              <div
                class="col-md-12 col-sm-12"
                style="text-align: left; margin-bottom: 5px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="etkezes"
                  @click="etkezesKapcsolas()"
                />
                <label class="form-check-label" for="etkezes">
                  &nbsp;Étel intolerancia/allergia
                </label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control rounded-3"
                  placeholder="Leave a comment here"
                  id="etkezesDesc"
                  style="height: auto; min-height: 100px"
                  v-model="jelentkezo.etkezesDesc"
                  required
                  disabled
                ></textarea>
                <label style="margin-left: 11px" for="etkezesDesc"
                  >Étel intoleranciára/allergiára vonatkozó információk</label
                >
              </div>
            </div>
            <h3>6. Egészségügyi információk</h3>
            <div class="row">
              <div
                class="col-md-12 col-sm-12"
                style="text-align: left; margin-bottom: 5px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="allergia"
                  @click="allergiaKapcsolas()"
                />
                <label class="form-check-label" for="allergia">
                  &nbsp;Allergia
                </label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control rounded-3"
                  placeholder="Leave a comment here"
                  id="allergiaDesc"
                  style="height: auto; min-height: 100px"
                  v-model="jelentkezo.allergiaDesc"
                  disabled
                  required
                ></textarea>
                <label style="margin-left: 11px" for="allergiaDesc"
                  >Allergiára vonatkozó információk</label
                >
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 col-sm-12"
                style="text-align: left; margin-bottom: 5px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="asztma"
                  @click="asztmaKapcsolas()"
                />
                <label class="form-check-label" for="asztma">
                  &nbsp;Asztma
                </label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control rounded-3"
                  placeholder="Leave a comment here"
                  id="asztmaDesc"
                  style="height: auto; min-height: 100px"
                  v-model="jelentkezo.asztmaDesc"
                  disabled
                  required
                ></textarea>
                <label style="margin-left: 11px" for="asztmaDesc"
                  >Asztmára vonatkozó információk</label
                >
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 col-sm-12"
                style="text-align: left; margin-bottom: 5px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="egproblem"
                  @click="egproblemKapcsolas()"
                />
                <label class="form-check-label" for="egproblem">
                  &nbsp;Egészségügyi probléma
                </label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control rounded-3"
                  placeholder="Leave a comment here"
                  id="egproblemDesc"
                  style="height: auto; min-height: 100px"
                  v-model="jelentkezo.egproblemDesc"
                  disabled
                  required
                ></textarea>
                <label style="margin-left: 11px" for="egproblemDesc"
                  >Egészségügyi problémákra vonatkozó információk</label
                >
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 col-sm-12"
                style="text-align: left; margin-bottom: 5px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gyogyszer"
                  @click="gyogyszerKapcsolas()"
                />
                <label class="form-check-label" for="gyogyszer">
                  &nbsp;Gyógyszer érzékenység
                </label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control rounded-3"
                  placeholder="Leave a comment here"
                  id="gyogyszerDesc"
                  style="height: auto; min-height: 100px"
                  v-model="jelentkezo.gyogyszerDesc"
                  disabled
                  required
                ></textarea>
                <label style="margin-left: 11px" for="gyogyszerDesc"
                  >Gyógyszer érzékenységre vonatkozó információk</label
                >
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 col-sm-12"
                style="text-align: left; margin-bottom: 5px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="baleset"
                  @click="balesetKapcsolas()"
                />
                <label class="form-check-label" for="baleset">
                  &nbsp;Korábbi baleset(ek)
                </label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control rounded-3"
                  placeholder="Leave a comment here"
                  id="balesetDesc"
                  style="height: auto; min-height: 100px"
                  v-model="jelentkezo.balesetDesc"
                  disabled
                  required
                ></textarea>
                <label style="margin-left: 11px" for="balesetDesc"
                  >Korábbi baleset(ek)re vonatkozó információk</label
                >
              </div>
            </div>
            <h3>7. Egyéb információ(k)</h3>
            <div class="row">
              <div class="form-floating mb-3">
                <textarea
                  class="form-control rounded-3"
                  placeholder="Leave a comment here"
                  id="egyeb"
                  style="height: auto; min-height: 100px"
                  v-model="jelentkezo.egyeb"
                ></textarea>
                <label style="margin-left: 11px" for="balesetDesc"
                  >Egyéb információ(k)</label
                >
              </div>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="informacio"
                @click="informacioKapcsolas()"
              />
              <label class="form-check-label" for="informacio">
                Feliratkozom a Miskolci Szakképzési Centrum hírlevelére, továbbá
                hozzájárulok, hogy részemre a Miskolci Szakképzési Centrum a
                középfokú beiskolázáshoz kapcsolódó információkat küldjön.
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="adatvedelem"
                @click="gombKapcsolas()"
              />
              <label class="form-check-label" for="adatvedelem">
                Jelen adatlap kitöltésével és beküldésével kijelentem, hogy az
                <a
                  href="https://www.miskolci-szc.hu/uploads/documents/45_2020--sz--kancellari-es-foig-utasotas-adatvedelmi-szabalyzat.pdf"
                  target="_blank"
                  >Adatkezelési tájékoztatót</a
                >
                elolvastam, az abban foglaltakat tudomásul vettem, illetve
                nyilatkozom, hogy a megadott adatok a valóságnak megfelelnek.
              </label>
            </div>
            <p></p>
            <button
              class="w-100 mb-2 btn btn-lg rounded-3 btn-success"
              id="jelentkezesGomb"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-people-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
                />
              </svg>
              Jelentkezés
            </button>
          </form>
        </div>
        <div v-else>
          <h1 style="color: red">
            Sajnáljuk, a táborban már nincs szabad hely!
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      jelentkezo: {
        id: 0,
        jelentkezoNev: "",
        jelentkezoSzhely: "",
        jelentkezoSzido: "",
        jelentkezoTaj: "",
        jelentkezoCimIrszam: "",
        jelentkezoCimVaros: "",
        jelentkezoCimCim: "",
        szuloNev: "",
        szuloTelefon: "",
        szuloEmail: "",
        szamlazasNev: "",
        szamlazasCimIrszam: "",
        szamlazasCimVaros: "",
        szamlazasCimCim: "",
        szamlazasAdoazon: "",
        etkezes: false,
        etkezesDesc: "",
        allergia: false,
        allergiaDesc: "",
        asztma: false,
        asztmaDesc: "",
        egproblem: false,
        egproblemDesc: "",
        gyogyszer: false,
        gyogyszerDesc: "",
        baleset: false,
        balesetDesc: "",
        egyeb: "",
        informacio: false,
      },
      jelentkezoId: 0,
      jo: true,
      tabornev: "",
      taborNeve: "",
      kontaktEmail: "",
      taborid: 0,
      adatlapLathato: true,
      szabadHelyekSzama: 0,
    };
  },
  methods: {
    refreshData() {},
    async BackendSearch() {
      let talaltam = false;
      const urlk = process.env.VUE_APP_URL.split(",");
      for (const index in urlk) {
        if (!talaltam) {
          let path = urlk[index] + "Kepzesek";
          await axios
            .get(path)
            .then((response) => {
              if (response.status == 200) {
                this.$store.state.API_URL = urlk[index];
                talaltam = true;
                //console.log(this.$store.state.API_URL);
                this.taborBeolvasasa();
                this.TelepulesekBeolvasasa();
                this.KozteruletekBeolvasasa();
              }
            })
            .catch((error) => {
              console.log("");
            });
        }
      }
    },
    async tajEllenorzes() {
      let url =
        this.$store.state.API_URL +
        "EgyediJelentkezes/" +
        this.$store.state.key +
        "?tajPluszKepzesId=T" +
        this.jelentkezo.jelentkezoTaj +
        this.taborid;
      await axios
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            alert("Erre a táborra már korábban jelentkezett!");
            this.jelentkezo.jelentkezoTaj = "";
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
    szamlazasCimIrszamBeir() {
      if (this.jelentkezo.szamlazasCimIrszam == "") {
        this.jelentkezo.szamlazasCimIrszam =
          this.jelentkezo.jelentkezoCimIrszam;
      }
    },
    szamlazasCimVarosBeir() {
      if (this.jelentkezo.szamlazasCimVaros == "") {
        this.jelentkezo.szamlazasCimVaros = this.jelentkezo.jelentkezoCimVaros;
      }
    },
    szamlazasCimCimBeir() {
      if (this.jelentkezo.szamlazasCimCim == "") {
        this.jelentkezo.szamlazasCimCim = this.jelentkezo.jelentkezoCimCim;
      }
    },
    gombKapcsolas() {
      let cb = document.getElementById("adatvedelem");
      let jg = document.getElementById("jelentkezesGomb");
      if (cb.checked) {
        jg.disabled = false;
      } else {
        jg.disabled = true;
      }
    },
    etkezesKapcsolas() {
      let cb = document.getElementById("etkezes");
      let bm = document.getElementById("etkezesDesc");
      if (cb.checked) {
        bm.disabled = false;
        this.jelentkezo.etkezes = true;
      } else {
        bm.disabled = true;
        this.jelentkezo.etkezesDesc = "";
        this.jelentkezo.etkezes = false;
      }
    },
    allergiaKapcsolas() {
      let cb = document.getElementById("allergia");
      let bm = document.getElementById("allergiaDesc");
      if (cb.checked) {
        bm.disabled = false;
        this.jelentkezo.allergia = true;
      } else {
        bm.disabled = true;
        this.jelentkezo.allergiaDesc = "";
        this.jelentkezo.allergia = false;
      }
    },
    asztmaKapcsolas() {
      let cb = document.getElementById("asztma");
      let bm = document.getElementById("asztmaDesc");
      if (cb.checked) {
        bm.disabled = false;
        this.jelentkezo.asztma = true;
      } else {
        bm.disabled = true;
        this.jelentkezo.asztmaDesc = "";
        this.jelentkezo.asztma = false;
      }
    },
    egproblemKapcsolas() {
      let cb = document.getElementById("egproblem");
      let bm = document.getElementById("egproblemDesc");
      if (cb.checked) {
        bm.disabled = false;
        this.jelentkezo.egproblem = true;
      } else {
        bm.disabled = true;
        this.jelentkezo.egproblemDesc = "";
        this.jelentkezo.egproblem = false;
      }
    },
    gyogyszerKapcsolas() {
      let cb = document.getElementById("gyogyszer");
      let bm = document.getElementById("gyogyszerDesc");
      if (cb.checked) {
        bm.disabled = false;
        this.jelentkezo.gyogyszer = true;
      } else {
        bm.disabled = true;
        this.jelentkezo.gyogyszerDesc = "";
        this.jelentkezo.gyogyszer = false;
      }
    },
    balesetKapcsolas() {
      let cb = document.getElementById("baleset");
      let bm = document.getElementById("balesetDesc");
      if (cb.checked) {
        bm.disabled = false;
        this.jelentkezo.baleset = true;
      } else {
        bm.disabled = true;
        this.jelentkezo.balesetDesc = "";
        this.jelentkezo.baleset = false;
      }
    },
    informacioKapcsolas() {
      let cb = document.getElementById("informacio");
      if (cb.checked) {
        this.jelentkezo.informacio = true;
      } else {
        this.jelentkezo.informacio = false;
      }
      //console.log(this.jelentkezo.informacio);
    },
    adatlapKapcsolas() {
      this.adatlapLathato = !this.adatlapLathato;
    },
    jelentkezoTarol() {
      //console.log(this.jelentkezo);
      let jg = document.getElementById("jelentkezesGomb");
      jg.textContent = "Jelentkezés feldolgozása...";
      jg.disabled = true;
      let url =
        this.$store.state.API_URL +
        "TaborbaJelentkezo/" +
        this.$store.state.key;
      axios
        .post(url, this.jelentkezo)
        .then((response) => {
          this.jelentkezoId = parseInt(response.data);
          this.jelentkezesTarol();
        })
        .catch((error) => {
          alert(error);
        });
    },
    async jelentkezesTarol() {
      let jelentkezes = {
        taborId: this.taborid,
        jelentkezoId: this.jelentkezoId,
      };
      let url =
        this.$store.state.API_URL +
        "TaborbaJelentkezes/" +
        this.$store.state.key +
        "?eMailCim=" +
        this.jelentkezo.szuloEmail +
        "&taborNeve=" +
        this.taborNeve +
        "&szuloNeve=" +
        this.jelentkezo.szuloNev +
        "&kontaktEmail=" +
        this.kontaktEmail;
      await axios
        .post(url, jelentkezes)
        .then((response) => {
          if (response.status == 200) {
            alert(response.data);
            this.egyediJelentkezesTarol();
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
    async egyediJelentkezesTarol() {
      let egyediJelentkezes = {
        id: 0,
        tajpluszkepzesid: "T" + this.jelentkezo.jelentkezoTaj + this.taborid,
      };
      //console.log(egyediJelentkezes);
      let url =
        this.$store.state.API_URL +
        "EgyediJelentkezes/" +
        this.$store.state.key;
      await axios
        .post(url, egyediJelentkezes)
        .then((response) => {
          if (response.status == 200) {
            //alert(response.data);
            this.refreshData();
            const closeTab = () => window.close(``, `_parent`, ``);
            closeTab();
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
    async taborBeolvasasa() {
      var splitUrl = window.location.href.split("#");
      let url = this.$store.state.API_URL + "Taborok?url=" + splitUrl[1];
      await axios
        .get(url)
        .then((response) => {
          let tabor = response.data;
          if (tabor.length == 0) {
            this.jo = false;
          } else {
            this.jo = true;
            this.taborid = tabor[0].id;
            this.taborNeve = tabor[0].taborNev;
            this.kontaktEmail = tabor[0].kontaktEmail;
            this.szabadHelyekSzama = tabor[0].maxletszam + 2;
            this.tabornev =
              tabor[0].taborNev +
              " (" +
              tabor[0].taborKezd.substring(0, 10).replaceAll("-", ".") +
              " - " +
              tabor[0].taborVeg.substring(0, 10).replaceAll("-", ".") +
              ")\nHelyszín: " +
              tabor[0].helyszinNev +
              "\nCím: " +
              tabor[0].helyszinIr +
              " " +
              tabor[0].helyszinVaros +
              ", " +
              tabor[0].helyszinCim +
              "\nKapcsolattartó: " +
              tabor[0].kontakt +
              ", tel:" +
              tabor[0].kontaktTelefon +
              "\nSzabad helyek száma: ";
          }
        })
        .catch((error) => {
          this.jo = false;
          alert(error);
        });
      url =
        this.$store.state.API_URL +
        "TaborbaJelentkezes?taborId=" +
        this.taborid;
      await axios
        .get(url)
        .then((response) => {
          this.tabornev += this.szabadHelyekSzama - response.data;
          if (this.szabadHelyekSzama - response.data <= 0) {
            this.adatlapLathato = false;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  mounted: function () {
    this.BackendSearch();
  },
};
</script>

<style scoped>
@media screen and (min-width: 1200px) {
  div#divUnder1200px {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  div#divAbove1200px {
    display: none;
  }
}

h3,
h4 {
  text-align: left;
}
h3 {
  font-weight: bold;
  margin-top: 20px;
}
.icon {
  color: rgb(153, 181, 4);
  font-size: 70px;
}
button {
  margin-top: 10px;
}

.form-check {
  margin-top: 10px;
}
</style>
