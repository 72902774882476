<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  props: {
    msg: String,
  },
  components: {},
  computed: {},
  data() {
    return {






































    };
  },
  methods: {
    refreshData() {},
    closeClick() {
      this.$router.push("/");
    },
  },
  mounted: function () {
    this.refreshData();
  },
};
</script>

<style>
#loginButton {
  margin-right: 100px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  margin-top: 30px;
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
